<template>
  <div class="commodity">
    <van-sticky>
      <van-nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        safe-area-inset-top
        :border="false"
      />
    </van-sticky>
    <van-skeleton title :row="6" :loading="loading">
      <div class="content-main">
        <div class="pic-mian">
          <img :src="state.coverPicUrl" />
        </div>
        <div class="desc" v-if="state.suitInfo">
          {{ state.suitInfo }}
        </div>
        <div class="commodity-set">
          <!-- <van-grid :column-num="2" :border="false">
            <van-grid-item
              v-for="item in state.materialList"
              :key="item.materialId"
            >
              <goods-item :good-info="item" />
            </van-grid-item>
          </van-grid> -->
          <div class="goods-item-box">
            <div
              class="goods-item"
              v-for="item in state.materialList"
              :key="item.materialId"
            >
              <goods-item :good-info="item" />
            </div>
          </div>
        </div>
        <div class="btm-submit-bar">
          <div class="btm-box">
            <div>
              <div>
                <span>总计:</span>
                <span class="red">￥{{ state.totalPrice }}</span>
                <span class="discount">{{ state.discount }}折</span>
              </div>
              <div class="origin-price">原价：¥{{ state.originPrice }}</div>
            </div>
            <div>
              <van-button
                type="primary"
                round
                style="background:#4D6FF0;width:3.306rem;height:1.12rem"
                hairline
                @click="handleAddToCart"
                >一键加购</van-button
              >
            </div>
          </div>
        </div>
      </div>
    </van-skeleton>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "@vue/runtime-core";
import { Toast } from "vant";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { addMaterialToShopCart, getSuitShop } from "../../api/common";
import GoodsItem from "../../components/GoodsItem.vue";
const router = useRouter();
const route = useRoute();
const store = useStore();
const token = computed(() => store.getters.token);
const materialId = computed(() => store.getters.materialId);
const id = route.query.id;
const title = route.query.name;
const loading = ref(true);
const state = reactive({
  hasDeleted: "",
  suitName: "",
  coverPicUrl: "",
  totalPrice: "",
  suitInfo: "",
  materialList: "",
  discount: "",
  originPrice: "",
});

const onClickLeft = () => {
  router.back();
};
const handleAddToCart = async () => {
  let param = {
    token: token.value,
    materialStockId: materialId.value,
    suitId: id,
  };
  let data = await addMaterialToShopCart(param);
  if (data) {
    state.show = false;
    Toast.success({ message: "加入购物车成功" });
    store.commit("SET_EMPTYCART", false);
  }
};
const loadSuitShopInfo = async () => {
  Toast.loading({ duration: 300, message: "加载中" });
  loading.value = true;
  let data = await getSuitShop({ token: token.value, materialSuitId: id });
  state.coverPicUrl = data.coverPicUrl;
  state.suitName = data.coverPicUrl;
  state.hasDeleted = data.hasDeleted;
  state.totalPrice = data.totalPrice;
  state.materialList = data.materialList;
  state.suitInfo = data.suitInfo;
  state.originPrice = data.originPrice;
  state.discount = data.discount;
  loading.value = false;
};
loadSuitShopInfo();
</script>

<style lang="scss" scoped>
.commodity {
  .content-main {
    background-color: rgb(250, 250, 250);
    padding: 12px;
    margin-bottom: 40px;
    .goods-item-box {
      display: flex;
      flex-wrap: wrap;
      .goods-item {
        margin-bottom: 9px;
      }
      .goods-item:nth-child(2n-1) {
        margin-right: 9px;
      }
    }
    .pic-mian {
      img {
        width: 100%;
        height: 150px;
        object-fit: cover;
        border-radius: 10px;
      }
    }
    .desc {
      text-align: left;
      padding: 10px 8px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
      font-size: 14px;
      line-height: 22px;
      color: #171725;
      margin-top: 12px;
    }
    .btm-submit-bar {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 100;
      width: 100%;
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
      background-color: #fff;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      .btm-box {
        padding: 6px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: left;
        font-size: 14px;
        .origin-price {
          color: #92929d;
          font-size: 10px;
          font-family: "DIN";
        }
      }
    }
    .commodity-set {
      margin-top: 12px;
    }
  }
}
.red {
  color: #fe3a30;
  margin-right: 10px;
  font-family: "DIN";
}
.discount {
  display: inline-block;
  font-size: 11px;
  color: #fe3a30;
  border-radius: 4px;
  padding: 6 8px;
  border: 1px solid #fe3a30;
}
</style>
